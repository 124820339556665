import React from "react"
import Img from "gatsby-image"
import * as styles from "./repeating-image.module.scss"
const RepeatingImage = ({ images }) => {
  const { single_image } = images

  return (
    <Img
      fluid={single_image.value[0].fluid}
      alt={single_image.value[0].description}
      className={styles.image}
    />
  )
}

export default RepeatingImage
