import React from "react"
import Service from "./Service"
import * as styles from "./service.module.scss"
const ServiceComparison = ({ services, meta }) => {
  return (
    <div className={styles.serviceGrid}>
      {services.value.map(({ elements, id }) => (
        <Service key={id} serviceInfo={elements} meta={meta} />
      ))}
    </div>
  )
}

export default ServiceComparison
