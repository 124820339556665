import React from "react"
import ImageLink from "../image-link/ImageLink"
import RepeatingImage from "../repeating-image/RepeatingImage"
import * as styles from "./affiliation-slider.module.scss"
const AffiliationSlider = ({ heading, images, isFromContentBlock = false }) => {
  const affiliationContainer = isFromContentBlock
    ? `${styles.affiliationContainer} ${styles.whiteBg}`
    : styles.affiliationContainer
  return (
    <div className={affiliationContainer}>
      <div className={styles.affiliationSlider}>
        {heading && <h2>{heading}</h2>}
        <div className={styles.imageContainer}>
          {images.value.map(({ id, elements }) => {
            if (!!elements.internal_link || !!elements.url) {
              return <ImageLink key={id} imageLink={elements} />
            } else {
              return <RepeatingImage key={id} images={elements} />
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default AffiliationSlider
