import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import * as styles from "./blog.module.scss"
import { graphql, useStaticQuery } from "gatsby"
const BlogCard = ({ blog }) => {
  const { nodeEnvs } = useStaticQuery(getEnvs)
  const { blogParent } = nodeEnvs
  const {
    heading,
    featured_text,
    featured_image,
    slug,
    published_date,
  } = blog.elements
  const date = published_date.value.split(" ")
  const truncString = (str, max) => {
    if (str.length <= max) {
      return str
    } else {
      return `${str.slice(0, max)}...`
    }
  }

  return (
    <article>
      <div className={styles.blogHeaderWrapper}>
        <div className={styles.pubDateWrapper}>
          {" "}
          <span className={styles.publishDate}>
            {" "}
            {date[0].slice(0, 3)} {date[1]}{" "}
          </span>{" "}
          <div> {date[2]} </div>{" "}
        </div>
        <div className={styles.lineSep}></div>
        <p className={styles.blogTitle}>
          <Link to={`/blog/${blogParent + slug.value}`}>
            {truncString(heading.value, 70)}
          </Link>
        </p>
      </div>

      <Img fluid={featured_image.value[0].fluid} alt={featured_image.value[0].description} className={styles.blogImage} />

      <p className={styles.featuredText}>
        {truncString(featured_text.value, 155)}
      </p>
      <Link className="primary-button" to={`/blog/${blogParent + slug.value}`}>
        Read More
      </Link>
    </article>
  )
}

export const getEnvs = graphql`
  query Envs {
    nodeEnvs {
      blogParent
    }
  }
`

export default BlogCard
