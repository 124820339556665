import React from "react"
import ReviewImage from "./ReviewImage"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { shuffle } from "../../assets/js/helper"
const StaticReview = ({ staticReviews, reviewImages }) => {
  const { review, location, reviewer, review_source_link } = shuffle(
    staticReviews.value
  ).elements
  const { label } = location.length > 0 ? location.value[0].elements : ""
  return (
    <>
      <div className="container singleReview_container">
        <div className="singleReview_info">
          <h2>Customer Reviews</h2>
          <div className="review_quote">
            <RichTextElement value={review.value} />
          </div>
          <div className="review_details">
            <ReviewImage
              image={shuffle(reviewImages.value)}
              className="review-image"
            />
            <p>
              <strong>{reviewer.value}</strong> <br />
              {label && label.value}
            </p>
          </div>
          <a href={review_source_link.value}>Read More Reviews</a>
        </div>
      </div>
    </>
  )
}

export default StaticReview
