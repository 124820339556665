import React, { useContext } from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Img from "gatsby-image"
import { VscTriangleRight } from "react-icons/vsc"
import * as styles from "./faq.module.scss"
import { AppContext } from "../context/context"

const FAQ = ({ question, answer, image, pageTypes, hasHeading }) => {

  const { photoGalleryActive, photoGallerySlug, whereWeServiceActive, whereWeServiceSlug, } = useContext(AppContext)
  
  const faqAnswer = () => {
    if (answer.links && answer.links.length > 0) {
      return (
        <RichTextElement
          value={answer.value}
          links={answer.links}
          resolveLink={(link, domNode) => {
            const gallerySlug = photoGalleryActive
              ? `/${photoGallerySlug}/${link.url_slug}`
              : `/${link.url_slug}`
            const serviceAreaSlug = whereWeServiceActive === "true"
              ? `/${whereWeServiceSlug}/${link.url_slug}`
              : `/${link.url_slug}`
            switch (pageTypes[link.url_slug]) {
              case "pest":
                return (
                  <Link to={gallerySlug}>
                    {domNode.children[0].data}
                  </Link>
                )
              case "post":
                return (
                  <Link to={`/blog/${link.url_slug}`}>
                    {domNode.children[0].data}
                  </Link>
                )
              case "serviceArea":
                return (
                  <Link to={serviceAreaSlug}>
                    {domNode.children[0].data}
                  </Link>
                )
              case "page":
              default:
                return (
                  <Link to={`/${link.url_slug}`}>
                    {domNode.children[0].data}
                  </Link>
                )
            }
          }}
        />
      )
    } else {
      return <RichTextElement value={answer.value} />
    }
  }
  return (
    <div
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      className={styles.faq}
    >
      {hasHeading ? (
        <h3 itemProp="name" className={styles.question}>
          <span>
            <VscTriangleRight /> {question.value}
          </span>
        </h3>
      ) : (
        <h2 className={styles.question} itemProp="name">
          <span>
            <VscTriangleRight /> {question.value}
          </span>
        </h2>
      )}
      <div
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        className={styles.answer}
      >
        <div itemProp="text">{faqAnswer()}</div>
      </div>
      {image.value[0] !== undefined ? (
        <Img
          fluid={image.value[0].fluid}
          alt={image.value[0].description}
          className={styles.image}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default FAQ
