import React from "react"
import ReviewCard from "./ReviewCard"
import BgImg from "gatsby-background-image"
import styles from "./reviewBlock.module.scss"
import SocialLogos from "./SocialLogos"
import LandingGoogleRating from "../google-rating-banner/LandingGoogleRating"
import { graphql, useStaticQuery } from "gatsby"
const ReviewBlock = ({
  reviews,
  review_images,
  heading,
  sub_heading,
  socials,
  background,
}) => {
  const reviewsWithImages = reviews.value.map(({ elements, id }, i) => {
    return {
      id,
      elements: {
        ...elements,
        review_image: review_images.value[i].elements.review_image,
      },
    }
  })

  const { google_rating_banner } =
    useStaticQuery(getGRB).allKontentItemSettings.nodes[0].elements

  if (background.value.length > 0) {
    return (
      <BgImg fluid={background.value[0].fluid} className={styles.reviewWrapper}>
        <div
          className={`PrimaryColorBg ${styles.overlay}`}
          id="hero-override"
        ></div>
        <div className="container">
          {heading.value?.length > 0 && (
            <h2 className={styles.whiteHeading}>{heading.value}</h2>
          )}
          {sub_heading?.value?.length > 0 && <p>{sub_heading.value}</p>}
          <div className={styles.socialsWrapper}>
            <SocialLogos socials={socials} />
          </div>
          <div className={styles.reviewCardWrapper}>
            {reviewsWithImages.map(({ elements, id }, i) => {
              return <ReviewCard key={id} info={elements} number={i} />
            })}
          </div>
          {google_rating_banner && google_rating_banner.value.length > 0 && (
            <LandingGoogleRating
              url={google_rating_banner.value[0].elements.google_url.value}
            />
          )}
        </div>
      </BgImg>
    )
  } else {
    return (
      <section className={` ${styles.reviewWrapper} ${styles.reviewBlockAlt}`}>
        <div className="container">
          {heading.value?.length > 0 && <h2>{heading.value}</h2>}
          {sub_heading?.value?.length > 0 && <p>{sub_heading.value}</p>}
          <div className={styles.socialsWrapperAlt}>
            <SocialLogos socials={socials} />
          </div>
          <div className={styles.reviewCardWrapper}>
            {reviewsWithImages.map(({ elements, id }, i) => {
              return <ReviewCard key={id} info={elements} number={i} />
            })}
          </div>
          <LandingGoogleRating
            url={google_rating_banner.value[0].elements.google_url.value}
            isAlt={true}
          />
        </div>
      </section>
    )
  }
}

export const getGRB = graphql`
  {
    allKontentItemSettings {
      nodes {
        elements {
          google_rating_banner {
            value {
              ... on kontent_item_google_rating_banner {
                id
                elements {
                  google_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ReviewBlock
