import React, { useContext } from "react"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import { AppContext } from "../context/context"
import * as styles from "./content-banner.module.scss"
import { FaChevronRight } from "react-icons/fa"
import { Link } from "gatsby"
const ContentBanner = ({
  bgImage,
  secondaryImg,
  content,
  additionalOffices,
}) => {
  const { size } = useContext(AppContext)
  const { heading, buttons } = content.value[0].elements
  const bgOverlay = [
    `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.65) 95%)`,
    bgImage,
  ]

  const getLinks = () => {
    const { button_text, linked_page } = buttons.value[0].elements
    const { slug } = linked_page.value[0].elements
    return (
      <Link to={`/${slug.value}`} className=" accent-button">
        <span className={styles.buttonContent}>
          {button_text.value}
          <FaChevronRight className={styles.icon} />
        </span>
      </Link>
    )
  }
  return (
    <BgImg
      fluid={bgOverlay}
      alt={bgImage.description}
      className={styles.background}
    >
      <div className={`container ${styles.backgroundContent}`}>
        <h2>{heading.value}</h2>
        <div className={styles.locations}>
          {additionalOffices.value.map(({ elements, id }) => {
            const { address_line_1, address_line_2, city, state, zip, name } =
              elements
            return (
              <p key={id} className={styles.location}>
                <span>{name.value}</span>
                <br />
                <span>{address_line_1.value}</span> <br />
                {address_line_2.value !== "" && (
                  <span>
                    {address_line_2.value}
                    <br />
                  </span>
                )}
                <span>{city.value}, </span>
                <span>{state.value[0].name}</span> <span>{zip.value}</span>
              </p>
            )
          })}
        </div>
        {buttons && buttons.value.length ? getLinks() : null}
        {/* <Link to={`/${slug.value}`} className=" accent-button">
          <span className={styles.buttonContent}>
            {button_text.value}
            <FaChevronRight className={styles.icon} />
          </span>
        </Link> */}
      </div>

      {size > 767 && (
        <div className={styles.additionalImageContainer}>
          <Img
            fluid={secondaryImg}
            alt={secondaryImg.description}
            className={styles.additionalImage}
          />
        </div>
      )}
    </BgImg>
  )
}

export default ContentBanner
