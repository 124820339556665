import React from "react"
import FAQ from "./FAQ"

const MommyFAQ = ({ faq_list, heading, sub_heading, pageTypes }) => {
  const hasHeading = heading.value !== "" ? true : false
  return (
    <div itemScope itemType="https://schema.org/FAQPage">
      {heading.value && <h2>{heading.value}</h2>}
      {sub_heading.value && <h3>{sub_heading.value}</h3>}
      {faq_list.value.map(({ elements, id }) => {
        return (
          <FAQ
            question={elements.question}
            answer={elements.answer}
            image={elements.image}
            pageTypes={pageTypes}
            key={id}
            hasHeading={hasHeading}
          />
        )
      })}
    </div>
  )
}
export default MommyFAQ
