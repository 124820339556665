import React, { useContext } from "react"
import { AppContext } from "../context/context"
import * as styles from "./multi-content-block.module.scss"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
const TileNavSwitch = ({ blocks, tileNavSwitch, active }) => {
  const { handleSwitchClick, size } = useContext(AppContext)
  const { content, heading } = blocks

  return (
    <div className={styles.multiContentBlock}>
      <h2 className={styles.switcherHeading}>{heading.value}</h2>
      {size >= 1024 && content.value !== "<p><br/></p>" ? (
        <RichTextElement value={content.value} className={styles.content} />
      ) : null}
      {tileNavSwitch.value[0].codename === "yes" && (
        <button
          className={active ? `primary-button disabled` : `primary-button`}
          disabled={active ? true : false}
          onClick={handleSwitchClick}
        >
          View Programs
        </button>
      )}
    </div>
  )
}

export default TileNavSwitch
