import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import * as styles from "./navigation-tile.module.scss"
const NavigationTile = ({
  tiles,
  whereWeServiceSlug,
  whereWeServiceActive,
}) => {
  const {
    background_image: background,
    cta,
    heading,
    internal_link: link,
  } = tiles
  const { __typename } = link.value[0]
  const { slug } = link.value[0].elements
  let newSlug = slug.value
  if (
    __typename === "kontent_item_service_area" &&
    whereWeServiceActive === "true"
  ) {
    newSlug = `/${whereWeServiceSlug}/${slug.value}`
  }
  return (
    <Link to={newSlug} className={styles.navTileLink}>
      <div className={styles.navTileWrapper}>
        <div className={styles.navTileInner}>
          <h3 className={styles.navTileHeading}>{heading.value}</h3>
          <div className={styles.navTileSubHeading}>{cta.value}</div>
        </div>
        <Img fluid={background.value[0].fluid}  alt={background.value[0].description} className={styles.navTile} />
      </div>
    </Link>
  )
}

export default NavigationTile
