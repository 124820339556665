import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentBlockParser from "./ContentBlockParser"
import styles from "./content-block.module.scss"

const ContentBlocks = ({
  content,
  meta,
  enableBuyOnline,
  additionalOffices,
  currentPage,
  tileNavSwitch,
  whereWeServiceActive,
  isPost,
  isHome,
  isServiceAreaPage,
  isLandingPage,
}) => {
  const {
    allKontentItemPage,
    allKontentItemPest,
    allKontentItemPost,
    allKontentItemPostCopy,
    allKontentItemServiceArea,
  } = useStaticQuery(getSlugs)
  const pageTypes = {}
  allKontentItemPage.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "page"
  })
  allKontentItemPest.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "pest"
  })
  allKontentItemPost.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "post"
  })
  allKontentItemPostCopy.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "post"
  })
  allKontentItemServiceArea.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "serviceArea"
  })

  //ContentBlocks is a stepping stone element that does the work of splitting up each type of content block possible.
  let mapRunsAlt = 0 // keeps track of how many content blocks that need to be have alternating flex direction
  let numTileNavs = 0

  const numBlocks = content.value.length

  return (
    <article className="main-content">
      {content.value.map(({ id, elements, __typename }, i) => {
        const isBPCOBlock = elements?.html?.value?.includes("bpco-container")
        const embeddedBPCOContainer = isBPCOBlock
          ? `${styles.embeddedBpcoContainer}`
          : ""

        const isAltBackgroundColor =
          elements?.alt_background_color?.value?.length &&
          elements?.alt_background_color?.value[0]?.codename === "yes"
            ? `${styles.altBg}`
            : ""
        const noPadding =
          content.value[i].__typename === "kontent_item_review_block"
            ? true
            : false
        const landingForm =
          content.value[i].__typename === "kontent_item_form_banner"
            ? true
            : false

        let className = ""
        if (i === 0 && numBlocks > 0 && !isPost && !isHome) {
          className = `${isAltBackgroundColor} ${styles.firstBlock} ${embeddedBPCOContainer}`
        } else if (i === numBlocks - 1 && numBlocks > 1 && !isPost) {
          if (__typename !== "kontent_item_content_banner") {
            className = `${isAltBackgroundColor} ${styles.lastBlock} ${embeddedBPCOContainer}`
          }
        } else {
          className = `${isAltBackgroundColor} ${embeddedBPCOContainer}`
        }

        if (
          i === 0 &&
          numBlocks > 0 &&
          !isPost &&
          !isHome &&
          !noPadding &&
          !landingForm
        ) {
          className = `clearfix ${styles.firstBlock} ${styles.contentBlock} `
        } else if (i === 0 && isHome) {
          className = `clearfix ${styles.contentBlock} ${styles.navTiles} `
        } else if (i === 1 && isHome && !isLandingPage) {
          className = `clearfix ${styles.contentBlock} ${styles.firstBlock}`
        } else if (
          i === numBlocks - 1 &&
          numBlocks > 1 &&
          !isPost &&
          !isLandingPage
        ) {
          className = `clearfix ${styles.contentBlock} ${styles.lastBlock}`
        } else if (noPadding) {
          className = `${styles.reviewBlock} ${
            isLandingPage ? content.value[i].__typename : ""
          }`
        } else if (isLandingPage && landingForm) {
          className = styles.landingForm
        } else {
          className = `clearfix ${styles.contentBlock}`
        }

        if (
          elements.image?.value.length === 1 &&
          elements.content &&
          elements.heading
        ) {
          mapRunsAlt++
        }

        if (
          elements.navigation_tiles &&
          tileNavSwitch.value[0]?.codename === "yes"
        ) {
          numTileNavs++
        }

        return (
          <div key={id} className={className}>
            <ContentBlockParser
              blocks={elements}
              pageTypes={pageTypes}
              meta={meta}
              enableBuyOnline={enableBuyOnline}
              additionalOffices={additionalOffices}
              mapRuns={mapRunsAlt}
              currentPage={currentPage}
              tileNavSwitch={tileNavSwitch}
              numTileNavs={numTileNavs}
              position={i}
              blockCount={numBlocks}
              whereWeServiceActive={whereWeServiceActive}
              isPost={isPost}
              isServiceAreaPage={isServiceAreaPage}
              isLandingPage={isLandingPage}
            />
          </div>
        )
      })}
    </article>
  )
}

const getSlugs = graphql`
  query allSlugs {
    allKontentItemPage {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemPest {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemPost {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemPostCopy {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemServiceArea(
      filter: { elements: { slug: { value: { ne: "" } } } }
    ) {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
  }
`

export default ContentBlocks
