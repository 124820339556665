import React from "react"
import { Link } from "gatsby"
import * as styles from "./image-link.module.scss"
const ImageLink = ({ imageLink }) => {
  const { image, internal_link, url } = imageLink
  const altText = image.value[0].description.value

  if (internal_link.length > 0) {
    return (
      <div className={styles.imageLink}>
        <Link to={`/${internal_link}`}>
          <img src={image.value[0].fluid.src} alt={altText} />
        </Link>
      </div>
    )
  } else {
    return (
      <a href={url.value} className={styles.imageLink}>
        <img src={image.value[0].fluid.src} alt={altText} />
      </a>
    )
  }
}

export default ImageLink
