import React, { useContext } from "react"
import { AppContext } from "../context/context"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Gallery from "../image-gallery/Gallery"
import styles from "./content-block.module.scss"
import { VscTriangleRight } from "react-icons/vsc"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import InnerHTML from "dangerously-set-html-content"
import { getContent } from "../../assets/js/helper"
const BasicContentBlock = ({
  pageTypes,
  content,
  heading,
  subHeading,
  html,
  image,
  image_gallery,
  alt_background_color,
  slug,
  mapRuns,
  buttons,
  meta,
}) => {
  const { whereWeServiceActive, whereWeServiceSlug, blogParent, photoGallerySlug, photoGalleryActive,size } =
    useContext(AppContext)
  
  let gallerySlug
  if (slug !== undefined) {
    gallerySlug = photoGalleryActive
      ? `/${photoGallerySlug}/${slug.value}`
      : `/${slug.value}`
  }

  const getInnerGallery = () => {
    if (image_gallery?.value[0]) {
      return (
        <div className="container">
          <Gallery images={image_gallery.value[0].elements.gallery} />
        </div>
      )
    }
  }
  const getButtons = () => {
    if (buttons && buttons.value.length) {
      return (
        <div className={styles.buttonContainer}>
          {buttons.value.map(({ id, elements }, i) => {
            const { button_text, linked_page, anchor_reference } = elements
            if (linked_page) {
              const { slug } = linked_page.value[0].elements
              return (
                <Link
                  key={id}
                  to={`/${slug.value}`}
                  className={i === 0 ? "accent-button" : "solid-primary-button"}
                >
                  {button_text.value}
                </Link>
              )
            } else {
              return (
                <AnchorLink
                  key={id}
                  to={`${meta.breadcrumb.location}#${anchor_reference.value[0].name}`}
                  className={i === 0 ? "accent-button" : "solid-primary-button"}
                >
                  {button_text.value}
                </AnchorLink>
              )
            }
          })}
        </div>
      )
    }
  }
  let oddOrEven
  if (image?.value.length === 1 && content && (heading || subHeading)) {
    mapRuns % 2 ? (oddOrEven = "odd") : (oddOrEven = "even")
    return (
      <div className={`container ${styles.alternatingBlockWrapper}`}>
        {heading?.value && <h2>{heading.value}</h2>}
        <div className={`${oddOrEven} ${styles.alternatingContentBlock} `}>
          <div className={styles.basicText}>
            {subHeading?.value && (
              <div className="flex" style={{ marginBottom: "15px" }}>
                <div className={styles.flexItem}>
                  <VscTriangleRight color="#58585a" fontSize={25} />
                </div>
                <div className={styles.flexItem}>
                  <h3 className="alignLeft">{subHeading.value}</h3>
                </div>
              </div>
            )}
            {size < 768 && 
            <Img
            fluid={image.value[0].fluid}
            alt={image.value[0].description}
            className={styles.interiorContentImage}
          />
            }
            {getContent(
              content,
              pageTypes,
              styles,
              whereWeServiceActive,
              whereWeServiceSlug,
              photoGallerySlug,
              photoGalleryActive,
              blogParent
            )}
            {buttons && getButtons()}
            {slug?.value && (
              <Link
                to={gallerySlug}
                className="primary-button"
              >
                View Gallery
              </Link>
            )}
          </div>
          {size >= 768 && 
          <Img
            fluid={image.value[0].fluid}
            alt={image.value[0].description}
            className={styles.interiorContentImage}
          />
          }

        </div>
      </div>
    )
  }

  return (
    <div
      className={
        alt_background_color?.value[0]?.codename === "yes"
          ? `${styles.altBg} `
          : `${styles.contentBlockWrapper}`
      }
    >
      <div className={`container ${styles.contentBlock}`}>
        {heading.value &&
          (image?.value.length > 0 ? (
            <h2 className="alignLeft">{heading.value}</h2>
          ) : (
            <h2>{heading.value}</h2>
          ))}
        {subHeading?.value && <h3 className="alignLeft">{subHeading.value}</h3>}
        {image?.value.length > 0 && (
          <Img
            fluid={image.value[0].fluid}
            alt={image.value[0].description}
            className={styles.interiorContentImage}
          />
        )}
        {(html.value?.includes("afn-container") ||
          html.value?.includes("bpco-container")) && (
          <InnerHTML
            className={styles.embedded}
            contentEditable="false"
            html={html.value}
          ></InnerHTML>
        )}
        {getContent(
          content,
          pageTypes,
          styles,
          whereWeServiceActive,
          whereWeServiceSlug,
          photoGallerySlug,
          photoGalleryActive,
          blogParent
        )}
        {getInnerGallery()}
        {html.value &&
          !html.value.includes("afn-container") &&
          !html.value.includes("bpco-container") && (
            <div
              className={styles.embeddedVideo}
              contentEditable="false"
              dangerouslySetInnerHTML={{ __html: html.value }}
            ></div>
          )}
        {buttons && getButtons()}
      </div>
    </div>
  )
}

export default BasicContentBlock
