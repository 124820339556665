import React from "react"
import GlobalFooterItem from "./GlobalFooterItem"
import SingleReview from "../reviews/SingleReview"
import StaticReview from "../reviews/StaticReview"
import * as styles from "./global-footer.module.scss"
const GlobalFooter = ({
  content,
  phone,
  pestroutesReviews,
  staticReviews,
  reviewImages,
  meta,
  pestroutesLogo,
}) => {
  const { global_footer_items } = content.value[0].elements
  const getSingleReview = id => {
    return (
      <div className={styles.review} key={id}>
        {pestroutesReviews.value[0].codename === "yes" ? (
          <SingleReview
            reviewImages={reviewImages}
            pestroutesLogo={pestroutesLogo}
          />
        ) : (
          <StaticReview
            reviewImages={reviewImages}
            staticReviews={staticReviews}
          />
        )}
      </div>
    )
  }
  return (
    <>
      {global_footer_items.value.map(({ id, elements, system }) => {
        if (system && system.type === "review_position") {
          return getSingleReview(id)
        }
        return (
          <GlobalFooterItem
            key={id}
            blocks={elements}
            phone={phone}
            meta={meta}
          />
        )
      })}
    </>
  )
}

export default GlobalFooter
