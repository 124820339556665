import React, { useContext } from "react"
import { AppContext } from "../context/context"
import Img from "gatsby-image"
import styles from "./multi-content-block.module.scss"
import { getContent } from "../../assets/js/helper"

const MultiContentBlock = ({ blocks, pageTypes, isServiceAreaPage }) => {
  const { whereWeServiceActive, whereWeServiceSlug, blogParent } =
    useContext(AppContext)
  const { heading, content, image } = blocks
  const textAligmentClassName = isServiceAreaPage
    ? styles.alignLeft
    : "alignCenter"

  return (
    <div className={styles.block}>
      {image.value.length > 0 && (
        <Img
          className={styles.multiImage}
          loading="lazy"
          fluid={image.value[0].fluid}
          alt={image.value[0].description}
        />
      )}
      {heading.value && <h3 className={styles.heading}>{heading.value}</h3>}
      <div
        className={`${textAligmentClassName} ${
          !heading.value ? styles.contentWithoutHeading : ""
        }`}
      >
        {getContent(
          content,
          pageTypes,
          styles,
          whereWeServiceActive,
          whereWeServiceSlug,
          blogParent
        )}
      </div>
    </div>
  )
}

export default MultiContentBlock
