import React, { useContext } from "react"
import styles from "./blog.module.scss"
import { AppContext } from "../context/context"
import { getContent } from "../../assets/js/helper"
import Img from "gatsby-image"

const BlogPostV2 = ({
  main_content_section,
  first_content_section,
  featured_image,
  pageTypes,
}) => {
  const { whereWeServiceActive, whereWeServiceSlug, blogParent } =
    useContext(AppContext)
  return (
    <div className={styles.blogPostContainer}>
                <Img
        fluid={featured_image.value[0].fluid}
        className={styles.postImage}
        alt={featured_image.value[0].description}
      />
      {getContent(
        first_content_section,
        pageTypes,
        styles,
        whereWeServiceActive,
        whereWeServiceSlug,
        blogParent
      )}
      {getContent(
        main_content_section,
        pageTypes,
        styles,
        whereWeServiceActive,
        whereWeServiceSlug,
        blogParent
      )}
    </div>
  )
}

export default BlogPostV2
